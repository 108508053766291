import { Message } from 'primeng/api';

export interface NumberOption {
  name: string;
  code: string;
}

export interface ScheduleInterface {
  name: string;
  key: number;
  isSelected: boolean;
  allDay: boolean;
  fromTime: Date | null;
  toTime: Date | null;
  isNextDay: boolean;
  displayText: string;
}

export const ALL_DAY = '00:00-23:59';

export const Categories: any[] = [
  { name: 'Always-On', key: 1 },
  { name: 'Recurring', key: 2 },
];

export const PollingTimeOptions: NumberOption[] = [
  { name: '10 Minutes', code: '10m' },
  { name: '20 Minutes', code: '20m' },
  { name: '30 Minutes', code: '30m' },
  { name: '60 Minutes', code: '60m' },
  { name: '120 Minutes', code: '120m' },
];

export const Schedules = [
  {
    name: 'Mon',
    key: 1,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Tue',
    key: 2,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Wed',
    key: 3,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Thu',
    key: 4,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Fri',
    key: 5,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Sat',
    key: 6,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
  {
    name: 'Sun',
    key: 0,
    isSelected: false,
    allDay: false,
    fromTime: null,
    toTime: null,
    isNextDay: false,
    displayText: '',
  },
];

export const IndefiniteSelector = [
  {
    name: 'Indefinitely',
    key: true,
  },
];

export const NoSuppressionTime: any[] = [
  {
    name: 'Until False',
    key: null,
  },
  {
    name: 'None',
    key: 0,
  },
  {
    name: 'Until Custom Timeout',
    key: 1,
  },
];

export const DefaultTimeZone = {
  name: 'Asset Time zone',
  code: 'Asset Time zone',
};
export const DateMessage: Message[] = [
  { severity: 'warn', summary: '', detail: 'Monitoring enabled indefinitely' },
];

export const ScheduleMessage: Message[] = [
  { severity: 'info', summary: '', detail: 'Occurs every day at all times' },
];

export const AlwaysOnMessage: Message[] = [
  {
    severity: 'warn',
    summary: '',
    detail: 'Updates will be continuously monitored all the time',
  },
];

export const RecurringMessage: Message[] = [
  {
    severity: 'warn',
    summary: '',
    detail: 'Updates will only be monitored during the configured period',
  },
];

export const IndefiniteDateRange: Message[] = [
  {
    severity: 'warn',
    summary: '',
    detail: 'Indefinitely Selected: Monitoring enabled indefinitely',
  },
];

export const SuppressionMessage: Message[] = [
  {
    severity: 'warn',
    summary: '',
    detail:
      'Actions will only trigger when the condition is first met. The condition will need to resolve before it will trigger the actions again.',
  },
  {
    severity: 'warn',
    summary: '',
    detail:
      'Actions will trigger on every update that matches the condition. (Be careful!)',
  },
  {
    severity: 'warn',
    summary: '',
    detail:
      'Actions only trigger when the condition is first met. The condition will need to resolve or the timeout expires before it will trigger the actions again.',
  },
];

export const SpecificDaysAndTimesCategory: any[] = [
  { name: 'Everyday', key: -1 },
  { name: 'Weekdays', key: 0 },
  { name: 'Weekends', key: 1 },
  { name: 'Custom', key: 2 },
];

export const Everyday = [0, 1, 2, 3, 4, 5, 6];
export const Weekdays = [1, 2, 3, 4, 5];
export const Weekends = [0, 6];

export const GenericTime = {
  startTime: null,
  endTime: null,
  allDay: true,
};

export interface TimeScheduleInterface {
  startTime: null | Date;
  endTime: null | Date;
  allDay: boolean;
}
