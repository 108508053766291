import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endOfDay, format, isBefore, set, startOfDay } from 'date-fns';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ScheduleReportRequest } from '../components/report-config-inputs/report-config-inputs.component';
import {
  ALL_DAY,
  Everyday,
  ScheduleInterface,
  SpecificDaysAndTimesCategory,
  TimeScheduleInterface,
  Weekdays,
  Weekends,
} from '../configs/workflow-schedule-form.config';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  public getReportQuery(): object {
    return this.query;
  }
  public setReportQuery(q: any) {
    if (typeof q === 'string') {
      const query = new URLSearchParams(q);
      this.query = query;
    } else {
      this.query = { ...this.query, ...q };
    }
  }

  public getReportId(): string {
    return this.reportId;
  }
  public setReportId(id: string) {
    this.reportId = id;
  }
  public getReportName(): string {
    return this.reportName;
  }
  public setReportName(name: string) {
    this.reportName = name;
  }
  private query;
  private reportId;
  private reportName;
  specificDaysAndTimesCategory = SpecificDaysAndTimesCategory;
  everyday = Everyday;
  weekdays = Weekdays;
  weekends = Weekends;
  constructor(public http: HttpClient) {}

  public isSpecificDaysAndTimes(schedule: any[], genericTime): any {
    const days = schedule?.map((s) => s.dayOfWeek).sort((a, b) => a - b);
    const times = schedule?.map((s) => s.timeIntervals);
    const allEqual = times?.every((val, i, arr) => val === arr[0]);

    /// populate generic start time and end tome
    if (allEqual && times[0] !== ALL_DAY && times[0] !== undefined) {
      genericTime.allDay = false;
      genericTime.startTime = set(new Date(), {
        hours: Number(times[0]?.split('-')[0]?.split(':')[0]),
        minutes: Number(times[0]?.split('-')[0]?.split(':')[1]),
      });
      genericTime.endTime = set(new Date(), {
        hours: Number(times[0]?.split('-')[1]?.split(':')[0]),
        minutes: Number(times[0]?.split('-')[1]?.split(':')[1]),
      });
    }

    if (!schedule?.length) {
      return this.specificDaysAndTimesCategory[0];
    }

    if (
      (JSON.stringify(days) == JSON.stringify(this.everyday) && allEqual) ||
      (schedule?.length === 1 && schedule[0]?.dayOfWeek === -1)
    ) {
      return this.specificDaysAndTimesCategory[0];
    }

    if (JSON.stringify(days) == JSON.stringify(this.weekdays) && allEqual) {
      return this.specificDaysAndTimesCategory[1];
    }

    if (JSON.stringify(days) == JSON.stringify(this.weekends) && allEqual) {
      return this.specificDaysAndTimesCategory[2];
    }
    return this.specificDaysAndTimesCategory[3];
  }

  updateSchedule(
    reportId: string,
    scheduleId: string,
    schedule: ScheduleReportRequest
  ): Observable<any> {
    schedule.reportQuery = new URLSearchParams(schedule.reportQuery)
      .toString()
      .replace(/\+/g, '%20');

    return this.http.put(
      environment.baseUri + `/reports/v3/${reportId}/schedules/${scheduleId}`,
      schedule
    );
  }

  saveSchedule(
    reportId: string,
    schedule: ScheduleReportRequest
  ): Observable<any> {
    schedule.reportQuery = new URLSearchParams(schedule.reportQuery)
      .toString()
      .replace(/\+/g, '%20');

    return this.http.post(
      environment.baseUri + `/reports/v3/${reportId}/schedules`,
      schedule
    );
  }

  getScheduledReports(): Observable<any> {
    return this.http.get(environment.baseUri + `/reports/v3/scheduled`);
  }

  deleteScheduledReport(reportId: string, scheduleId: string): Observable<any> {
    return this.http.delete(
      environment.baseUri + `/reports/v3/${reportId}/schedules/${scheduleId}`
    );
  }

  getDateTimeForEachDay(
    isScheduledTask: boolean,
    selectedSpecificDaysAndTimes: any,
    genericTime: any,
    workflowType: string,
    schedules: any[]
  ): ScheduleInterface[] {
    const localScheduleList: any[] = [];
    switch (selectedSpecificDaysAndTimes.name) {
      case 'Everyday':
        this.getEveryDayDateTime(
          genericTime,
          workflowType,
          isScheduledTask,
          localScheduleList
        );
        break;

      case 'Weekdays':
      case 'Weekends':
        this.getWeekDateTimes(
          schedules,
          genericTime,
          workflowType,
          isScheduledTask,
          localScheduleList
        );
        break;

      case 'Custom':
        localScheduleList.push(
          ...schedules
            .filter((s) => s.isSelected)
            .map((s) => ({
              dayOfWeek: s.key,
              timeIntervals: this.getTimeIntervals(s, isScheduledTask),
            }))
        );
        break;

      default:
        break;
    }

    return localScheduleList;
  }

  private getWeekDateTimes(
    schedules: any[],
    genericTime: any,
    workflowType: string,
    isScheduledTask: boolean,
    localScheduleList: any[]
  ) {
    schedules
      .filter((s) => s.isSelected)
      .forEach((s) => {
        if (
          (genericTime.endTime &&
            genericTime.startTime &&
            workflowType !== 'scheduled') ||
          (genericTime.startTime && workflowType === 'scheduled')
        ) {
          const timeIntervals = isScheduledTask
            ? format(new Date(genericTime.startTime), 'HH:mm')
            : `${format(new Date(genericTime.startTime), 'HH:mm')}-${format(
                new Date(genericTime.endTime),
                'HH:mm'
              )}`;
          localScheduleList.push({
            dayOfWeek: s.key,
            timeIntervals,
          });
        } else {
          localScheduleList.push({
            dayOfWeek: s.key,
            timeIntervals: ALL_DAY,
          });
        }
      });
  }

  private getEveryDayDateTime(
    genericTime: any,
    workflowType: string,
    isScheduledTask: boolean,
    localScheduleList: any[]
  ) {
    if (
      (genericTime.endTime &&
        genericTime.startTime &&
        workflowType !== 'scheduled') ||
      (genericTime.startTime && workflowType === 'scheduled')
    ) {
      const timeIntervals = isScheduledTask
        ? format(new Date(genericTime.startTime), 'HH:mm')
        : `${format(new Date(genericTime.startTime), 'HH:mm')}-${format(
            new Date(genericTime.endTime),
            'HH:mm'
          )}`;
      localScheduleList.push({
        dayOfWeek: -1,
        timeIntervals,
      });
    } else {
      localScheduleList.push({
        dayOfWeek: -1,
        timeIntervals: ALL_DAY,
      });
    }
  }

  private getTimeIntervals(
    { allDay, fromTime, toTime }: ScheduleInterface,
    isScheduledTask: boolean
  ): string {
    if (allDay && !isScheduledTask) {
      return ALL_DAY;
    }

    const formatTime = (time: Date): string => format(time, 'HH:mm');

    return isScheduledTask
      ? formatTime(new Date(fromTime))
      : `${formatTime(new Date(fromTime))}-${formatTime(new Date(toTime))}`;
  }

  onSpecificDaysAndTimesCategorySelect(
    genericTime: TimeScheduleInterface,
    schedules: ScheduleInterface[],
    key: number,
    workflowType: string
  ): string {
    switch (key) {
      case -1:
        schedules.forEach((s) => {
          this.onSelectDay(s);
          Object.assign(s, {
            isSelected: true,
            allDay: true,
            fromTime: null,
            toTime: null,
          });
        });
        break;

      case 0:
        schedules.forEach((s) => {
          if (s.name !== 'Sun' && s.name !== 'Sat') {
            this.onSelectDay(s);
            Object.assign(s, {
              isSelected: true,
              allDay: true,
              fromTime: null,
              toTime: null,
            });
          } else {
            Object.assign(s, {
              isSelected: false,
              fromTime: null,
              toTime: null,
            });
          }
        });
        break;

      case 1:
        schedules.forEach((s) => {
          if (s.name === 'Sun' || s.name === 'Sat') {
            this.onSelectDay(s);
            Object.assign(s, {
              isSelected: true,
              allDay: true,
              fromTime: null,
              toTime: null,
            });
          } else {
            Object.assign(s, {
              isSelected: false,
              fromTime: null,
              toTime: null,
            });
          }
        });
        break;

      case 2:
        schedules.forEach((s) => {
          this.onSelectDay(s);
          Object.assign(s, {
            isSelected: false,
            allDay: false,
            fromTime: null,
            toTime: null,
          });
        });
        break;

      default:
        break;
    }

    return this.getGenericTimeText(genericTime, workflowType);
  }

  onSelectDay(schedule: ScheduleInterface, workType: string = '') {
    if (workType !== 'scheduled') {
      schedule.allDay = schedule.isSelected;
    }

    if (!schedule.isSelected) {
      schedule.fromTime = null;
      schedule.toTime = null;
    }
    schedule.displayText = this.getDisplayText(
      schedule.fromTime,
      schedule.toTime,
      schedule.allDay,
      schedule.isNextDay
    );
  }

  onSelectAllDay(schedule: ScheduleInterface) {
    if (schedule.allDay) {
      schedule.fromTime = null;
      schedule.toTime = null;
    } else {
      schedule.fromTime = startOfDay(new Date());
      schedule.toTime = endOfDay(new Date());
    }
    schedule.isNextDay = false;
    schedule.displayText = this.getDisplayText(
      schedule.fromTime,
      schedule.toTime,
      schedule.allDay,
      schedule.isNextDay
    );
  }

  onSelectGenericAllDay(
    time: TimeScheduleInterface,
    genericTime: TimeScheduleInterface,
    workflowType: string
  ): string {
    if (time.allDay) {
      genericTime.allDay = true;
      genericTime.startTime = null;
      genericTime.endTime = null;
    } else {
      genericTime.startTime = startOfDay(new Date());
      genericTime.endTime = endOfDay(new Date());
    }
    return this.getGenericTimeText(genericTime, workflowType);
  }

  getGenericTimeText(
    genericTime: TimeScheduleInterface,
    workflowType: string
  ): string {
    const { startTime, endTime, allDay } = genericTime;

    if (allDay) return 'All day';

    if (workflowType === 'scheduled' && startTime) {
      const formattedTime = format(new Date(startTime), 'h:mma');
      return `Starting at ${formattedTime}`;
    }

    if (!startTime && !endTime) return 'Enter start and end times';
    if (startTime && !endTime) return 'Enter end time';

    if (startTime && endTime) {
      const isNextDay = isBefore(new Date(endTime), new Date(startTime));
      return this.getDisplayText(startTime, endTime, allDay, isNextDay);
    }

    return '';
  }

  onTimeChange(schedule: ScheduleInterface) {
    schedule.isNextDay = isBefore(
      new Date(schedule.toTime),
      new Date(schedule.fromTime)
    );

    schedule.displayText = this.getDisplayText(
      schedule.fromTime,
      schedule.toTime,
      schedule.allDay,
      schedule.isNextDay
    );
  }

  onGenericTimeChange(
    time: TimeScheduleInterface,
    genericTime: TimeScheduleInterface,
    schedules: ScheduleInterface[],
    selectedSpecificDaysAndTimes: any,
    workflowType: string
  ): string {
    genericTime.allDay = false;

    const daysToIgnore = ['Sun', 'Sat'];
    const resetSchedule = (s) => {
      s.fromTime = null;
      s.toTime = null;
      s.allDay = false;
    };

    switch (selectedSpecificDaysAndTimes?.key) {
      case -1:
        schedules.forEach((s) => {
          s.fromTime = time.startTime;
          s.toTime = time.endTime;
          s.allDay = false;
        });
        break;

      case 0:
        schedules.forEach((s) => {
          if (!daysToIgnore.includes(s.name)) {
            s.fromTime = time.startTime;
            s.toTime = time.endTime;
            s.allDay = false;
          } else {
            resetSchedule(s);
          }
          this.onSelectDay(s);
        });
        break;

      case 1:
        schedules.forEach((s) => {
          if (daysToIgnore.includes(s.name)) {
            s.fromTime = time.startTime;
            s.toTime = time.endTime;
            s.allDay = false;
          } else {
            resetSchedule(s);
          }
          this.onSelectDay(s);
        });
        break;

      case 2:
        schedules.forEach(resetSchedule);
        break;

      default:
        break;
    }

    return this.getGenericTimeText(genericTime, workflowType);
  }

  getDisplayText = (
    fromTime: Date,
    toTime: Date,
    isAllDay: boolean,
    isNextDay: boolean
  ): string | null => {
    if (isAllDay) {
      return null;
    }

    const displayFromTime = this.formatTime(fromTime);
    const displayToTime = this.formatTime(toTime);

    let displayText = '';

    if (displayFromTime && displayToTime) {
      displayText = `${displayFromTime}-${displayToTime}`;

      if (isNextDay) {
        displayText += this.getNextDayText(isNextDay);
      }
    }

    return displayText;
  };

  formatTime = (time: Date): string => {
    if (time) {
      return format(time, 'h:mma');
    }
    return '';
  };

  getNextDayText(isNextDay: boolean) {
    return isNextDay ? ' next day' : '';
  }

  validateSchedule(
    workflowType,
    scheduleControl,
    genericTime,
    selectedSpecificDaysAndTimes,
    schedules
  ): void {
    if (workflowType === 'scheduled') {
      this.validateScheduledWorkflow(
        scheduleControl,
        genericTime,
        selectedSpecificDaysAndTimes,
        schedules
      );
    } else {
      this.validateOtherWorkflow(
        scheduleControl,
        genericTime,
        selectedSpecificDaysAndTimes,
        schedules
      );
    }

    scheduleControl.updateValueAndValidity();
  }

  private validateScheduledWorkflow(
    scheduleControl,
    genericTime,
    selectedSpecificDaysAndTimes,
    schedules
  ) {
    if (this.isNotKeyTwoAndAllDay(selectedSpecificDaysAndTimes, genericTime)) {
      genericTime.startTime = null;
      genericTime.endTime = null;
    } else if (
      this.isNotKeyTwoWithStartTime(selectedSpecificDaysAndTimes, genericTime)
    ) {
      this.setValueAndResetErrors(scheduleControl, genericTime);
    } else if (this.isNotKeyTwo(selectedSpecificDaysAndTimes)) {
      this.setErrorToRequired(scheduleControl);
    } else {
      this.handleSchedulesValidation(scheduleControl, schedules);
    }
  }

  private isNotKeyTwoAndAllDay(
    selectedSpecificDaysAndTimes,
    genericTime
  ): boolean {
    return selectedSpecificDaysAndTimes?.key !== 2 && genericTime?.allDay;
  }

  private isNotKeyTwoWithStartTime(
    selectedSpecificDaysAndTimes,
    genericTime
  ): boolean {
    return selectedSpecificDaysAndTimes?.key !== 2 && genericTime?.startTime;
  }

  private isNotKeyTwo(selectedSpecificDaysAndTimes): boolean {
    return selectedSpecificDaysAndTimes?.key !== 2;
  }

  private setValueAndResetErrors(scheduleControl, genericTime): void {
    scheduleControl.setValue(genericTime.startTime);
    scheduleControl.setErrors(null);
  }

  private setErrorToRequired(scheduleControl): void {
    scheduleControl.setErrors({
      required: true,
    });
    scheduleControl.setValue(null);
  }

  private handleSchedulesValidation(scheduleControl, schedules): void {
    if (this.hasSelectedSchedules(schedules)) {
      if (this.isAnySelectedScheduleMissingFromTime(schedules)) {
        this.setErrorToRequired(scheduleControl);
        return;
      }

      scheduleControl.setValue(schedules);
      scheduleControl.setErrors(null);
    } else {
      this.setErrorToRequired(scheduleControl);
    }
  }

  private hasSelectedSchedules(schedules): boolean {
    return this.countSelectedSchedules(schedules) > 0;
  }

  private countSelectedSchedules(schedules): number {
    return schedules.filter((schedule) => schedule.isSelected).length;
  }

  private isAnySelectedScheduleMissingFromTime(schedules): boolean {
    return Boolean(
      schedules.find((schedule) => schedule.isSelected && !schedule.fromTime)
    );
  }

  private validateOtherWorkflow(
    scheduleControl,
    genericTime,
    selectedSpecificDaysAndTimes,
    schedules
  ) {
    if (
      selectedSpecificDaysAndTimes?.key === 2 &&
      schedules.some((schedule) => schedule.isSelected)
    ) {
      return scheduleControl.setErrors(null);
    }

    if (
      !genericTime?.allDay &&
      (!genericTime?.startTime || !genericTime?.endTime)
    ) {
      return scheduleControl.setErrors({ required: true });
    }

    return scheduleControl.setErrors(null);
  }
}
